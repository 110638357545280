.Header {
	background-color: $secondary-color;
	line-height: 70px;
	@include media-breakpoint-up(lg) { 
		height: 70px;
	} 
}

.Logo {
	@include media-breakpoint-down(lg) { 
		text-align: center;
	}
	&-full {
		width: 199px;
	}
}

.Menu {
	&-content {
		display: block;
		background: none;
		position: relative;
		top: 0px;
		height: 100%;
		min-height: auto;
		z-index: 5;
	}
	&-item {
		border-bottom: solid 3px $white;
		display: inline-block;
		list-style-type: none;
		font-size: 1.1rem;
		margin-left: -3px;
		margin-right: -3px;
		padding-left: .5rem;
		padding-right: .5rem;
		list-style: none;
		@include media-breakpoint-down(sm) {
			font-size: .8rem;
		}
		&.Player {
			padding: 0 !important;
		}
		&.selected {
			border-bottom-color: $primary-color;
		}
		&:hover {
			border-bottom: solid 3px $primary-color;
			cursor: pointer;
		}
	}
	&-link {
		color: $white;
		font-family: $font-family;
		&.selected {
			color: $primary-color;
			text-decoration: none;
		}
		&:hover {
			color: $primary-color;
			text-decoration: none;
		}
	}
	&-list {
		list-style: none;
		margin: 0;
		padding: 2px 12px;
		text-align: center;
	}
	&-player {
		display: block;
		color: white;
		background: $primary-color;
		width: 75px;
		@include media-breakpoint-down(sm) {
			width: 60px;
		}
	}
}

// .Nav-header {
// 	align-items: center;
// 	grid-template-columns: auto 1fr auto auto auto;
//     grid-template-areas: "live menu actions login search";
// }