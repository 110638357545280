.AudioPlayer {
	display: none;
}

.carousel {
	height: 624px;
	@include media-breakpoint-down(xs) {
		height: 255px;
	}
	&-item {
		&:not(:first-child) {
			.MainSection-content:before {
				display: none;
			}
		}
	}
}

.HomePosts {
	margin-top: 100px;
}

.MainSection {
	max-height: 624px;
	overflow: hidden;
	position: relative;
	@include media-breakpoint-down(xs) {
		max-height: 229px;
	}
	&-firstSlide {
		.carousel-inner {
			height: 100%;
		}
		.carousel-item {
			height: 100%;
		}
	}
	&-content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		&:before {
			bottom: 0;
			content: " ";
			display: block;
			background: url('../../img/kappa.png') no-repeat;
			height: 543px;
			position: absolute;
			left: -15%;
			width: 466px;
			z-index: 4;
			@include media-breakpoint-down(sm) {  
				display: none;
			}
		}
		&Play {
			text-align: center;
			width: 100%;
		}
		&Titles {
			max-width: 600px;
			position: relative;
			text-align: left;
			width: 100%;
			z-index: 5;
		}
	}
	&-contentTitle-1 {
		background: $primary-color;
		color: $white;
		font-size: 2rem;
		@include media-breakpoint-down(sm) {
			font-size: 1.5rem;
			line-height: 1;
		}
	}
	&-contentTitle-2 {
		background: $secondary-color;
		color: $white;
		font-size: 1.3rem;
		&:after {
			content: " ";
			display: block;
		}
	}
	&-secondSlide {
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 99;
		
	}
}

.Play {
	background: $white;
	border: solid 8px $secondary-color;
	border-radius: 170px;
	box-sizing: content-box;
	display: inline-block;
	height: 170px;
	position: relative;
	box-shadow: 0 0 0 11px $white;
	text-align: center;
	width: 170px;
	z-index: 5;
	@include media-breakpoint-down(xs) {
		height: 50px;
		width: 50px;
	}
	.fas {
		color: $secondary-color;
		font-size: 5rem;
		line-height: 170px;
		margin-left: 18px;
		vertical-align: middle;
		@include media-breakpoint-down(xs) {
			font-size: 2rem;
			line-height: 51px;
			margin-left: 9px;
		}
	}
	.fa-pause {
		margin-left: 0; 
	}
}

.Ad{
	padding: 50px 0px;

	&-wrapper{
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;

		height: 350px;
		max-width: 870px;
		width: 100%;

		margin: 0 auto;
	}

	&-image{
		display: block;

		height: 100%;

		flex-basis: 35%;		
	}

	&-info{
		background-color: $white;	
		
		padding-left: 70px;
		padding-right: 20px;
		padding-top: 40px;

		position: relative;

		text-transform: uppercase;

		height: 100%;	
		
		flex-basis: 100%;
		width: 100%;

		&::after{
			content: '';
			background-image: url('#{$img-path}/radio-one-mic.png');
			background-size: cover;

			display: block;

			height: 100%;
			
			max-width: 158px;
			width: 100%;

			position: absolute;
			
			bottom: 0;
			right: 10px;
		}
	}

	&-infoWrapper{
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		justify-content: center;		
	}

	&-title{
		color: $tertiary-color;
		font-size: 2.5rem;
		line-height: 0.8;

		margin-bottom: 20px;

		max-width: 340px;

		.radio-one-sec-color{
			font-size: 28px;
		}
	}

	&-offers{
		color: $tertiary-color;
		font-size: 14px;
		
		padding-left: 8px;
		margin-bottom: 20px;
	}

	&-note{
		display: block;

		color: $secondary-color;
		font-size: 14px;

		margin-bottom: 15px;

		max-width: 250px;
	}

	&-quote{
		background-color: $tertiary-color;
		color: $white;

		border-radius: 2rem;

		font-size: 1rem;

		padding: 8px 24px;

		transition: background-color 0.5s ease;

		&:hover{
			background-color: $secondary-color;
			color: $white;

			text-decoration: none;
		}
	}	

	@include media-breakpoint-down(sm){
		&-wrapper{
			flex-direction: column;			
			height: auto;
		}

		&-image{
			flex-basis: 100%;
			width: 100%;
		}

		&-info{
			text-align: center;

			&::after{
				display: none;
			}
		}

		&-infoWrapper{
			align-items: center;
		}

		&-title{
			font-size: 2rem;
			max-width: 100%;
		}

		&-offers{
			align-self: flex-start;
			text-align: left;
		}

		&-note{
			max-width: 100%;
		}

		&-quote{
			display: block;			
			margin-bottom: 10px;
		}
	}	

	@include media-breakpoint-down(md){
		&-info{
			padding-left: 25px;
			padding-right: 25px;

			max-width: 100%;

			&::after{
				display: none;
			}			
		}

		&-title{
			font-size: 2rem;
			max-width: 100%;
		}
	}
}

.Policy {
	background: rgba(255,255,255,0.6);
	box-sizing: border-box;
	margin-bottom: 2rem;
	margin-left: auto;
	margin-right: auto;
	margin-top: 2rem;
	max-width: 800px;
	padding: 2rem;
	width: 100%;
	&-title {
		text-align: center;
	}
}

.SocialCols {
	text-align: center;
	width: 100%;
}

.SocialCol {
	&-face {
		border-right: solid 2px $white;
		display: inline-block;
		text-align: center;
		width: 49.5%;
		a {
			background: url('../../img/facebook.png');
			display: inline-block;
			height: 52px;
			width: 52px;
		}
	}
	&-twitter {
		display: inline-block;
		text-align: center;
		width: 49.5%;
		a {
			background: url('../../img/twitter.png');
			display: inline-block;
			height: 52px;
			width: 52px;
		}
	}
}
