.Posts{
	margin-top: 100px;
	padding: 1rem 0rem;

	&-title{
		color: white;
		font-size: 1.5rem;
		text-align: left;
		text-transform: uppercase;

		margin-bottom: 3rem; 
		padding-bottom: 0.25rem;	

		border-bottom: 7px solid $primary-color;
	}
}

.PostItem{
	color: $white;

	display: flex;

	align-items: center;
	justify-content: space-around;

	flex-direction: row;
	flex-wrap: nowrap;

	height: 280px;

	margin: 25px 0px;
	padding: 15px 0px;

	&-image{
		display: block;
		flex-shrink: 0;

		margin-bottom: 0%;
		margin-right: 25px;

		height: 100%;
		max-width: 50%;
		width: 100%;

		object-fit: cover;
  		object-position: center;
	}

	&-content{
		display: flex;

		align-items: flex-start;
		justify-content: space-between;

		height: 100%;

		flex-direction: column;
		flex-wrap: nowrap;
	}	

	&-title{
		display: block;
		
		font-size: 1.25rem;

		margin-bottom: 5px;
		width: 100%;
	}

	&-date{
		display: block;
			
		color: $primary-color;
		font-size: 1rem;

		margin-bottom: 0.75rem;
		width: 100%;
	}

	&-description{
		font-size: 0.75rem;
		margin-bottom: 1rem;
	}

	&-readMore{
		background-color: $primary-color;
		
		color: $secondary-color;		
		text-transform: uppercase;

		border-radius: 1rem;
 
		padding: 0.25rem 1.25rem;

		transition: all 0.5s ease;

		&:hover{
			background-color: $secondary-color;
			color: $primary-color;
			text-decoration: none;
		}
	}
}

@include media-breakpoint-down(md){
	.PostItem{
		align-items: center;
		flex-direction: column;	
		
		height: auto;

		&-image{			
			margin-bottom: 10px;
			margin-right: 0;

			max-width: 100%;
			width: 100%
		}

		&-content{
			text-align: center;
		}		

		&-readMore{
			align-self: center;
		}
	}
}

@include media-breakpoint-only(lg){
	.PostItem{
		height: 310px;
	}
}

.Article{
	color: $black;	

	margin-top: 100px;
	padding-top: 60px;

	&-sectionTitle{
		border-bottom: 7px solid $primary-color;

		color: $white;
		font-size: 1.25rem;
		text-transform: uppercase;

		display: block;	

		margin-bottom: 0px;	
		padding-bottom: 0.25rem;

		width: 100%;
	}

	&-banner{
		display: block;

		height: auto;
		max-height: 400px;
		overflow: hidden;
		width: 100%;
		img {
			width: 100%;
			height: auto;
		}
	}

	&-wrapper{
		padding-top: 70px;
		padding-bottom: 150px;

		padding-left: 80px;
		padding-right: 80px;
	}

	&-date{
		display: block;
		
		font-size: 0.75rem;
		
		margin-bottom: 1rem;
		
		width: 100%;
	}

	&-title{
		font-size: 2rem;
		line-height: 1.5;
		margin-bottom: 85px;
	}

	&-content{
		font-size: 1rem;
		line-height: 2.5;
	}

	@include media-breakpoint-down(sm){
		&-wrapper{
			padding-left: 15px;
			padding-right: 15px;
		}

		&-title, &-content{
			line-height: 1.5;
		}

		&-wrapper{
			padding-top: 40px;
			padding-bottom: 100px;
		}

		&-title{
			margin-bottom: 40px;
		}
	}
}

.RelatedPosts{
	padding: 60px 0px;
}

