.ContactUs{
    margin-top: 100px;
    padding: 1rem 0rem;

    &-title{
		color: white;
		font-size: 1.5rem;
		text-align: left;
		text-transform: uppercase;

		margin-bottom: 3rem; 
		padding-bottom: 0.25rem;	

		border-bottom: 7px solid $primary-color;
    }
    
    &-contentWrapper{
        padding: 3rem 3rem;
    }

    @include media-breakpoint-down(md){
        &-contentWrapper{
            padding: 0;
        }   
    }

    &-content{
        color: white;
        font-size: 1.5rem;
        line-height: 2.0;
    }

    &-headset{
        display: block;
        width: 100%;
    }

    &-form{
        padding: 3rem 1rem;
    }

    &-nameInput, &-emailInput{
        height: 60px;

        padding-bottom: 0px;
        padding-top: 0px;
    }

    &-nameInput, &-emailInput, &-messageInput{
        border: 1px solid $secondary-color;
        margin-bottom: 1.25rem;

        &::-webkit-input-placeholder {
            color: $placeholder-color;
            font-size: 1rem;
        }
        
        &::-moz-placeholder {
            color: $placeholder-color;
            font-size: 1rem;
        }
        
        &::-ms-placeholder {
            color: $placeholder-color;
            font-size: 1rem;
        }
        
        &::placeholder {
            color: $placeholder-color;
            font-size: 1rem;
        }
    }

    &-formNote{
        color: $white;

        font-size: 16px;
        line-height: 1;
    }

    &-formSubmit{
        display: block;

        border-radius: 2rem;

        background-color: $secondary-color;
        color: white;

        font-size: 1rem;
        font-weight: 700;

        padding-bottom: 9px;
        padding-top: 9px;

        transition: background-color 0.5s ease;

        width: 100%;

        &:hover{
            background-color: $primary-color;            
        }
    }
}