@font-face {
	font-family: $font-family;
	font-style: $font-style;
	font-weight: $font-weight;
	src: $src;
}

body {
	font-family: $font-family;
	background: url('../../img/bg-1.png'), url('../../img/bg-2.png') #000;
	background-position: center center, center center;
	background-repeat: no-repeat, no-repeat;
	background-size: 100% auto;
}

.GridContainer {

}

// .MainContent {

// }

.radio-one-bg-white{
	background-color: $white;
}

.radio-one-sec-color{
	color: $secondary-color;
}

.radio-one-title-logo{
	position: relative;

	&::before{
		content: '';
		
		background-image: url('#{$img-path}/radio-one-logo-small.png');
		background-repeat: no-repeat;
		background-size: cover;

		display: block;

		height: 140px;
		width: 270px;

		position: absolute;
		bottom: -30px;
		left: 70%;

		@include media-breakpoint-down(lg){
			right: 0;
		}

		@include media-breakpoint-down(md){
			left: 60%;
		}

		@include media-breakpoint-down(sm){
			height: 54px;
			width: 105px;

			left: unset;
			right: 0;
			bottom: -40%;
		}
	}
}

.radio-one-third-color{
	color: #321f64;
}