
// Fonts


//Fontawesome
@import url('https://use.fontawesome.com/releases/v5.1.1/css/all.css');

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap/scss/bootstrap";

@import "default/_common";
@import "default/_header";
// @import "default/_tags";
// @import "default/_tabs";
@import "default/_home";
@import "default/_posts";
@import "default/contactUs";
// @import "default/_services";
@import "default/_footer";
