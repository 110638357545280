.Footer {
	&-copy {
		color: $white;
		display: block;
		font-size: .65rem;
		width: 100%;
	}
	&-info {
		text-align: center;
	}
	&-link {
		color: $white;
		font-size: 3vw;
		text-decoration: none;
	}
	&-links {
		margin-top: 2rem;
	}
	&Links {
		&-item {
			text-align: center;
		}
		&-link {
			color: $white;
		}
		&-wrapper {
			margin: 0;
			padding: 0;
			text-align: center;
		}
	}
	&-wrapper {
		background: #000;
		border-top: solid 4px $white;
		height: auto;
		margin-top: 125px;
		min-height: 285px;
		padding-bottom: 50px;
		padding-top: 50px;
		position: relative;
		width: 100%;
		&:before {
			//background: url("../../img/triangle-footer.png") #000;
			content: " ";
			display: block;
			height: 61px;
			left: 0;
			margin-left: auto;
			margin-right: auto;
			position: absolute;
			right: 0;
			top: -61px;
			width: 105px;
		}
	}
}