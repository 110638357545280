/*
// Body
$body-bg: #f5f8fa;

// Borders
$cakephp-border-color: darken($body-bg, 10%);
$list-group-border: $cakephp-border-color;
$navbar-default-border: $cakephp-border-color;
$panel-default-border: $cakephp-border-color;
$panel-inner-border: $cakephp-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
*/

//Fonts
$font-family: 'Futura Md BT Medium';
$font-style: normal;
$font-weight: normal;
$src: local('Futura Md BT Medium'), url('/font/futura_medium_bt-webfont.woff') format('woff');

//Colors
$white: #fff;
$black: #000;
$font-color: $black;
$primary-color: #00e300;
$secondary-color: #23395b;
$tertiary-color: #321f64;
$gray: #a8a5ac;
$dark-gray: #666666;
$light-gray: #f8f8f8;
$placeholder-color: #7a7a7a;
 
//Brand
$brand-color-1: #3c1206;
$brand-color-2: #8b5c30;

//Titles
$post-title-color: #898b8d;
$post-subtitle-color: #9e9e9e;

//Margins
$margin-standard: 20px;
$margin-special: 30px;

//Path
$img-path: "../../img" !default; 